<template>
  <v-card elevation="0" class="pa-0">
    <v-card-text class="pa-0">
      <v-btn ref="audio" elevation="0" fab dark :color="!toggle ? 'grey' : (speaking ? 'green' : 'red darken-3')"
        @click.stop="toggle ? endSpeechRecognition() : startSpeechRecognition()" :class="{ 'animated infinite pulse': toggle }">
        <v-icon>mdi-microphone</v-icon>
      </v-btn>
      <p style="
              margin-top: 10px;
              margin-bottom: 1px;
              color: #8492A6;
              font-size: 12px;">
        Klik tombol mic, Katakan "Saya akan melakukan test dengan baik" untuk mencoba microphone
      </p>
      <p style="
              margin-top: 1px;
              color: #8492A6;
              font-size: 12px;">Pastikan tombol mic berwarna hijau ketika anda berbicara
      </p>
      <div v-if="sentences.length > 0">
        <div style="font-size: 12px;color: #3C4858;">Hasil percobaan microphone :</div>
        <p class="mb-0">
          <span v-for="(sentence, i) in sentences.length > 0 ? sentences : []" :key="i">{{ sentence + ' ' }}</span>
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
let recognition = SpeechRecognition ? new SpeechRecognition() : false

export default {
  name: 'speech-to-text',
  props: {
    lang: {
      type: String,
      default: 'id-ID'
    },
    text: {
      type: [String, null],
      required: true
    },
    isSection: {
      type: Boolean,
      default: false
    },
    isConnected: {
      type: Boolean,
      default: false
    },
    isDetected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      error: false,
      speaking: false,
      toggle: false,
      runtimeTranscription: '',
      sentences: [],
      array_text: []
    }
  },
  methods: {
    checkCompatibility() {
      if (!recognition) {
        this.error = 'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
      }
    },
    endSpeechRecognition() {
      recognition.stop()
      this.sentences = []
      this.array_text = []
      this.runtimeTranscription = ''
      this.toggle = false
      this.$emit('speechend', {
        sentences: this.sentences,
        text: this.sentences.join('. ')
      })
    },
    startSpeechRecognition() {
      if (!recognition) {
        this.error = 'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
        return false
      }
      this.toggle = true
      this.$emit('isconnected', {
        MicConnected: true
      })


      recognition.lang = this.lang
      recognition.interimResults = true

      recognition.addEventListener('speechstart', () => {
        // console.log(event);
        this.speaking = true
        // this.$emit('isdetected', {
        //   MicDetected: true
        // })
      })

      recognition.addEventListener('speechend', () => {
        // console.log(event);
        this.speaking = false
        this.array_text = []
      })

      recognition.addEventListener('result', event => {
        Array.from(event.results).map(result => result[0]).map(result => this.array_text.push(result.transcript))

        // console.log(this.array_text[this.array_text.length - 1]);

        // let arr = this.array_text[this.array_text.length - 1]
        // console.log('arr',arr);
        // console.log('split_length',arr.split(" ").length);

        const text = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('')

        this.runtimeTranscription = text
      })

      recognition.addEventListener('end', () => {
        if (this.runtimeTranscription !== '') {
          this.sentences.push(this.capitalizeFirstLetter(this.runtimeTranscription))
          this.$emit('isdetected', {
            MicDetected: true,
            text: this.runtimeTranscription
          })
          this.$emit('update:text', `${this.text}${this.sentences.slice(-1)[0]}. `)
        }
        else {
          this.$emit('isdetected', {
            MicDetected: true,
            text: ""
          })
        }
        this.runtimeTranscription = ''
        recognition.stop()
        if (!this.toggle) {
          try { recognition.start() } catch (e) { console.debug() }
        }
      })
      try {
        recognition.start()
      } catch (e) {
        console.debug()
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
  async mounted() {
    await this.checkCompatibility()
    // this.startSpeechRecognition()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
