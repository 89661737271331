<template>
  <v-row class="white">
    <v-col>
      <div class="text-center">Silahkan tunggu sebentar</div>
      <v-row justify="center">
        <v-col cols="12" md="8" style="padding: 2rem">
            <v-progress-linear indeterminate/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  mounted() {
    const a = document.createElement("a");
    a.href = "https://potensa.amikom.ac.id/";
    a.click();
  },
};
</script>