// import data_section from '../../store/data_section'

export default {
  data() {
    return {
      section: {},
      current: 0,
      loading: {
        state: false,
        text: '',
        progress: 0
      },
      show_direction: false,
      show_close_statement: false,
    }
  },
  computed: {
    parts() {
      return this.section.exam ? this.section.exam : [];
    },
    lists() {
      return !this.parts.length ? [] : this.parts.map(part => part.questions).reduce((val, part) => [...val, ...part]);
    },
    questions() {
      return !this.lists.length ? [] : this.lists.map(list => list.list_question).reduce((val, list) => [...val, ...list]);
    },
    current_question: {
      get() {
        return this.current ? this.questions[Number.parseInt(this.current) - 1] : {};
      },
      set(value) {
        this.$set(this.questions, this.current - 1, value);
        // this.questions[Number.parseInt(this.current) - 1] = value;
      }
    },
    current_list() {
      return this.current_question ? this.lists.find(list => list.list_question.some(question => (question.id || question) == (this.current_question.id || this.current_question))) : {};
    },
    current_part() {
      return this.current_list ? this.parts.find(part => part.questions.some(list => list == this.current_list)) : {};
    },
    // Baca diriku: array.slice().shift() itu sama dengan array[0]
    beginning_of_list() {
      return this.current_question && this.current_question.id == this.current_list.list_question.slice().shift().id;
    },
    beginning_of_part() {
      return this.beginning_of_list && this.current_list == this.current_part.questions.slice().shift();
    },
    beginning_of_section() {
      return this.beginning_of_part && this.current_part == this.parts.slice().shift();
    },
    // Baca diriku: array.slice().pop() itu sama dengan array[array.length - 1]
    end_of_list() {
      return this.current_question.id && this.current_question.id == this.current_list.list_question.slice().pop().id;
    },
    end_of_part() {
      return this.end_of_list && this.current_list == this.current_part.questions.slice().pop();
    },
    end_of_section() {
      return this.end_of_part && this.current_part == this.parts.slice().pop();
    }
  },
  mounted() {
    // this.fetchSection();
  },
  methods: {
    // Fetch nya masih demo ya
    // async fetchSection() {
    //   this.loading.state = true;
    //   this.loading.text = 'Please Wait';
    //   setTimeout(async () => {
    //     this.section = data_section.exam;

    //     let arr = [];

    //     // detail exam
    //     arr = this.parts;
    //     for (let index = 0; index < arr.length; index++) {

    //       // await this.$store.dispatch('question/detail_public', arr[index])
    //       //   .then(res => {
    //       //     this.loading.text = `Please Wait ` + (index + 1) + "/" + arr.length;
    //       //     this.loading.progress = ((index + 1) / arr.length) * 100;
    //       //     this.$set(this.questions, index, res.data);
    //       //   }).catch(error => {
    //       //     this.loading.text = error.message;
    //       //     console.error(error);

    //       this.loading.text = `Getting part ${arr[index].part}`;
    //       await this.$store
    //         .dispatch("exam/detail", arr[index].exam_id)
    //         .then((res) => res.data)
    //         .then((data) => {
    //           this.$set(arr, index, { ...arr[index], ...data });
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //         });
    //     }

    //     arr = this.lists;
    //     for (let index = 0; index < arr.length; index++) {
    //       // Detail question
    //       let arr2 = arr[index].list_question;
    //       for (let index2 = 0; index2 < arr2.length; index2++) {
    //         this.current++;
    //         await this.$store
    //           .dispatch("question/detail_public", {
    //             id: arr2[index2],
    //             company_id: this.current_part.company.id,
    //           })
    //           .then((res) => res.data)
    //           .then((data) => {
    //             this.loading.text = `Please wait (${this.current}/${this.questions.length})`;
    //             this.loading.progress = this.current / this.questions.length * 100;
    //             this.$set(arr2, index2, data);
    //           })
    //           .catch((error) => {
    //             console.error(error);
    //           });
    //       }
    //     }

    //     setTimeout(() => {
    //       this.loading.state = false
    //     }, 100);
    //   }, 2000);
    // },
    next() { },
    prev() { },
    go_to(number) {
      this.current = number
    },
    submit() {

    },
  },
}