<template>
	<v-row :no-gutters="$vuetify.breakpoint.smAndUp">
		<div class="font-weight-bold mb-3">{{ act.title }}</div>
		<v-card elevation="0" class="col-md-12">
			<div class="pa-4">
				<div class="pa-4">
					<p class="text-body-1  text-justify mb-4" v-html="this.act.theory.description"></p>
					<template v-if="this.act.theory.meeting.days != null">
						<div v-for="(item, index) in this.act.theory.meeting.days" :key="index">
							<p>Hari {{ day(item.day) }} , <span v-for="(time, index2) in item.times" :key="index2">pukul {{ time.start_time }} - {{time.end_time}} WIB</span></p>
						</div>
					</template>
					<template v-if="this.act.theory.meeting.dates != null">
						<div v-for="(item, index) in this.act.theory.meeting.dates" :key="index">
							<p>Tanggal {{ date(item.date) }} , <span v-for="(time, index) in item.times" :key="index+'T'">pukul {{ time.start_time }} - {{time.end_time}} WIB</span></p>
						</div>
					</template>
					<v-card elevation="0" class="py-3 rounded-lg">
						<iframe v-if="this.act.theory.link_embed.url != ''" :src="this.act.theory.link_embed.url" style="height: 500px;border-style: hidden;"></iframe>
					</v-card>
					<p class="pt-1 mb-1" v-if="act.theory.meeting.link != ''">Link Meeting : </p>
					<template v-if="act.theory.meeting.is_clicked">
						<v-btn
							small
							color="primary"
							dark
							depressed
							class="white px-2 rounded-lg"
							:href="this.act.theory.meeting.link" target="_blank"
							v-if="act.theory.meeting.link != ''"
              @click="changeIsClickedBtn()"
            >
							Klik Disini
						</v-btn>
					</template>
					<template v-else>
						<v-btn
							small
							color="primary"
							dark
							depressed
							class="white px-2 rounded-lg"
							:href="this.act.theory.meeting.link" target="_blank"
							v-if="act.theory.meeting.link != ''"
							@click="changeIsClickedBtn(); changeMeetingClicked()"
            >
							Klik Disini
						</v-btn>
					</template>
					<div v-if="act.theory.meeting.link != ''" class="mt-3">
						<div v-for="(item, index) in this. act.theory.meta_data" :key="'A'+index">
							<p>{{ item.title }} : {{ item.content }}</p>
						</div>
					</div>
					<template v-if="act.is_complete == false && act.end_test_at === null">
            <div class="pt-3" v-if="is_clicked_btn">
              <v-btn
                depressed
                class="px-2 rounded-lg"
                color="primary"
                :loading="btn_loading"
                @click="getUnlock()">
                Selesaikan Aktivitas
              </v-btn>
            </div>
						<template v-if="act.theory.meeting.is_must_participate">
							<div class="pt-3" v-if="act.theory.meeting.is_clicked && act.theory.meeting.link == ''">
								<v-btn
									depressed
									class="px-2 rounded-lg"
									color="primary"
									:loading="btn_loading"
									@click="getUnlock()">
									Selesaikan Aktivitas
								</v-btn>
							</div>
						</template>
						<template v-else>
							<div class="pt-3" v-if="act.theory.meeting.is_clicked">
								<v-btn
									depressed
									class="px-2 rounded-lg"
									color="primary"
									:loading="btn_loading"
									@click="getUnlock()">
									Selesaikan Aktivitas
								</v-btn>
							</div>
						</template>
					</template>
				</div>
			</div>
		</v-card>
	</v-row>
</template>

<script>
import moment from "moment";

export default {
	props: {
		act: Object,
		detail_class: Object,
		id_topic: String,
		id_act: String,
	},
	data() {
		return {
			btn_loading: false,
      is_clicked_btn: false,
		}
	},
	methods: {
		day(a) {
			if (a === 'sunday') {
				return 'Minggu'
			} else if (a === 'monday') {
				return 'Senin'
			} else if (a === 'tuesday') {
				return 'Selasa'
			} else if (a === 'wednesday') {
				return 'Rabu'
			} else if (a === 'thursday') {
				return 'Kamis'
			}
			else {
				return 'Jumat'
			}
		},
		date(a) {
			return a ? moment(a).locale('en-gb').format("L") : null;
		},
    changeIsClickedBtn() {
      this.is_clicked_btn = true
    },
		changeMeetingClicked() {
			this.btn_loading = true
			const data = {
				member_redeem_id: this.detail_class.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			this.axios.put(`/users/v1/member/activity/update_meeting_is_clicked`, data)
			.then(() => {
				this.btn_loading = false
				this.$emit('getActFalse', this.id_topic, this.id_act)
			}).catch(() => {
				this.btn_loading = false
			})
		},
		getUnlock() {
			this.btn_loading = true
			const data = {
				program_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			this.axios.post(`/users/v1/member/activity/unlock`, data)
			.then(async () => {
				this.btn_loading = false
				this.$emit('getActFalse', this.id_topic, this.id_act)
				this.$emit('getDetailClass')
			})
			.catch(() => {
				this.btn_loading = false
			});
		},
	},
}
</script>