<template>
  <v-app v-if="!isCompatible">
    <v-dialog v-model="showDialog" persistent fullscreen>
      <v-card flat>
        <v-card-text class="my-16">
          <div class="text-h4 text-center mb-4">
            <v-icon color="warning" size="128">ri-alert-line</v-icon>
          </div>
          <div class="text-h4 text-center mb-4">
            Browser yang Anda gunakan tidak kompatibel.
          </div>
          <div class="text-h6 text-center">
            Silakan gunakan browser Google Chrome dengan versi terbaru untuk
            melanjutkan tes.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
  <div v-else-if="!loading && mounted">
    <template v-if="$store.state.company.under_maintenance">
      <auth v-if="$store.getters.isLoggedIn && bypass" />
      <maintenance v-else />
    </template>
    <template v-else>
      <guest v-if="!$store.getters.isLoggedIn" />
      <auth v-else-if="$store.getters.isLoggedIn" />
    </template>
  </div>
</template>

<script>
import { detect } from "detect-browser";

export default {
  metaInfo: {
    title: "Prakerja",
  },
  components: {
    auth: require("./views/template/Auth").default,
    guest: require("./views/template/Guest").default,
    maintenance: require("./views/template/Maintenance").default,
  },
  data() {
    return {
      loading: true,
      bypass: false,
      mounted: false,
      browser: {},
      showDialog: true,
    };
  },
  computed: {
    isCompatible() {
      return (
        this.browser.name == "chrome" &&
        !["ios", "android os"].includes(this.browser.os.toLocaleLowerCase())
      );
    },
  },
  created() {
    this.browser = detect();
  },
  mounted() {
    this.initFaceApi();
  },
  methods: {
    async initFaceApi() {
      this.mounted = true;
    },
    getCompanySetting() {
      this.$store.dispatch("getCompanySetting").then(() => {
        this.checkBypass();
        this.loading = false;
      });
    },
    checkBypass() {
      if (localStorage.getItem("ytc_lms_auth") === null) {
        this.bypass = false;
      } else {
        const data = localStorage.getItem("ytc_lms_auth");
        if (this.$store.state.company.phone_number.includes(data.phone)) {
          this.bypass = true;
        } else {
          this.bypass = false;
        }
      }
    },
  },
  beforeMount() {
    this.getCompanySetting();
  },
};
</script>

<style lang="scss">
.theme--light.v-application {
  background-color: var(--v-background-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html div {
  font-family: "Poppins" !important;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-up {
  from {
    width: 100%;
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    left: 0;
    right: 0;
  }
  to {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-enter-active {
  animation: fade 0.3s;
}
.fade-up-leave-active {
  animation: fade-up 0.3s reverse;
}
.fade-up-enter-active {
  animation: fade-up 0.3s;
}
a {
  text-decoration: none;
}
.color-state {
  color: #3c4858 !important;
}
</style>