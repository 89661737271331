<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <div>
        <v-dialog v-model="attend" width="500" persistent>
          <v-card class="text-center">
            <v-card-title class="text-h5 text-center d-block">
              Perhatian
            </v-card-title>

            <v-card-text class="pb-0">
              <p class="fs-16 color-strike">
                Bagi kamu pengguna TPA Amikom, pastikan data diri kamu terutama
                nama sesuai dengan akun TPA Amikom. Perbedaan identitas dapat
                menyebabkan sertifikat tidak keluar.
              </p>
              <v-alert
                dense
                color="#8492A6"
                icon="ri-information-line"
                class="fs-16 border-0 transparent color-strike"
              >
                Untuk akses pelatihan lebih mudah, kami sarankan menggunakan
                perangkat Android, Laptop, atau PC. Apabila ada kendala silahkan
                hubungi CS kami.
              </v-alert>
            </v-card-text>
            <v-card-actions class="d-block pb-3">
              <v-btn
                color="primary"
                @click="attend = false"
                depressed
                class="rounded-xl px-4 text-center"
              >
                Oke
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Topnav / App bar -->
        <v-main class="grey">
          <v-app-bar class="white" flat fixed>
            <v-toolbar-title>
              <div class="my-1 text-left">
                <img
                  src="@/assets/images/logo_amikom.png"
                  alt="Avatar"
                  class="img-yec-test"
                  @click="Beranda()"
                />
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn class="d-none d-md-block transparent" elevation="0" @click="Beranda">
              <v-icon class="me-2">ri-home-3-line</v-icon> Beranda
            </v-btn>
            <v-btn class="d-none d-md-block transparent" elevation="0" @click="Kelas">
              <v-icon class="me-2">ri-slideshow-line</v-icon> Kelas Saya
            </v-btn> -->
            <v-list dense flat class="d-none d-md-block">
              <v-list-item-group color="primary" style="display: flex">
                <template v-for="item in items">
                  <v-list-item
                    :disabled="
                      !user.is_updated_profile && item.title != 'Account'
                    "
                    :key="item.link"
                    class="py-1 pl-6"
                    active-class="menu-active"
                    :to="item.link"
                    @click="pageChanged(0, item)"
                  >
                    <v-list-item-icon class="mr-2 fs-14">
                      <v-icon
                        v-text="item.icon"
                        class="color--text"
                        :class="
                          user.is_updated_profile ||
                          item.title == 'Account' ||
                          'text--lighten-3'
                        "
                      ></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        class="fs-14 color--text"
                        :class="
                          user.is_updated_profile ||
                          item.title == 'Account' ||
                          'text--lighten-3'
                        "
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <v-menu offset-y class="d-none d-md-block transparent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="d-none d-md-block px-2 font-weight-regular"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!user.is_updated_profile"
                  elevation="0"
                  rounded
                  color="transparent"
                >
                  <v-icon class="mr-3">ri-user-line</v-icon>
                  <span
                    class="text-truncate d-inline-block"
                    style="max-width: 80px"
                    >{{ user.fullname }}</span
                  >
                  <v-icon class="mx-2" small>$dropdown</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <!-- <v-list-item link @click="Tele">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-briefcase-line</v-icon>
                      <div class="pl-2 my-auto">Bimbingan Prakerja</div>
                    </div>
                  </v-list-item-title>
                </v-list-item> -->
                <v-list-item link @click="goToSubscription">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-coupon-3-line</v-icon>
                      <div class="pl-2 my-auto">Subskripsi</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="Logout">
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-icon>ri-logout-circle-line</v-icon>
                      <div class="pl-2 my-auto">Logout</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mdAndUp"
              @click="dialog.menuAuth = true"
            ></v-app-bar-nav-icon>
            <v-dialog
              v-model="dialog.menuAuth"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar>
                  <v-toolbar-title>
                    <div class="my-1 text-left">
                      <img
                        src="@/assets/images/logo_amikom.png"
                        alt="Avatar"
                        class="img-yec-test"
                        @click="Beranda()"
                      />
                    </div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      icon
                      dark
                      @click="dialog.menuAuth = false"
                      color="primary"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer></v-spacer>
                <v-card-text class="pa-5">
                  <v-list dense id="list-menu-user">
                    <!-- <v-list-item link @click="Prakerja">
                      <v-list-item-title>
                        <v-icon>ri-community-line</v-icon> Kelas Prakerja
                        </v-list-item-title
                      >
                    </v-list-item> -->
                    <v-list-item link @click="Kelas">
                      <v-list-item-title>
                        <v-icon>ri-slideshow-line</v-icon> Kelas
                        Saya</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="">
                        <v-list-group :value="false" class="p-0">
                          <template v-slot:activator>
                            <v-list-item-content class="">
                              <v-list-item-title class="mb-2">
                                <v-icon>ri-user-line</v-icon>
                                {{ user.fullname }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <v-list-item link @click="Setting">
                            <v-list-item-title>
                              <v-icon>ri-user-settings-line</v-icon> Setting
                              Profile</v-list-item-title
                            >
                          </v-list-item>
                          <!-- <v-list-item link @click="Tele">
                            <v-list-item-title> <v-icon>ri-briefcase-line</v-icon> Bimbingan Prakerja</v-list-item-title>
                          </v-list-item> -->
                          <v-list-item link @click="goToSubscription">
                            <v-list-item-title>
                              <v-icon>ri-coupon-3-line</v-icon>
                              Subskripsi</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item link @click="Logout">
                            <v-list-item-title>
                              <v-icon>ri-logout-circle-line</v-icon>
                              Logout</v-list-item-title
                            >
                          </v-list-item>
                        </v-list-group>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showScan = true">
                      <v-list-item-title>
                        <img
                          src="@/assets/images/barcode.svg"
                          width="18"
                          class="mr-2"
                        />
                        Scan Absen</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-app-bar>

          <v-container fluid style="margin-top: 64px">
            <!-- <v-dialog
              v-model="attend"
              width="500"
              persistent
            >

              <v-card class="text-center">
                <v-card-title class="text-h5 text-center d-block">
                  Perhatian
                </v-card-title>

                <v-card-text class="pb-0">
                  <p class="fs-18 color-disable">Bagi kamu pengguna Kartu Prakerja, pastikan data diri kamu terutama nama sesuai dengan akun Kartu Prakerja. Perbedaan identitas dapat menyebabkan sertifikat tidak keluar.</p>
                  <v-alert
                    dense
                    
                    color="#8492A6"
                    icon="ri-information-line"
                    class="fs-18 border-0 transparent color-disable"
                  >
                    Untuk akses pelatihan lebih mudah, kami sarankan menggunakan perangkat Android, Laptop, atau PC.
                    Apabila ada kendala silahkan hubungi CS kami.
                  </v-alert>
                </v-card-text>
                <v-card-actions class="d-block pb-3">
                <v-btn
                    color="primary"
                    @click="attend = false"
                    
                    class="rounded-xl px-4 text-center"
                  >
                    Oke
                  </v-btn> 
                </v-card-actions>               
              </v-card>
            </v-dialog> -->
            <transition name="fade-up">
              <router-view v-if="loaded"></router-view>
            </transition>
          </v-container>
          <div class="py-3 white">
            <div class="container">
              <div class="row">
                <div class="col-md-5">
                  <img
                    src="@/assets/images/logo_amikom.png"
                    alt="Avatar"
                    class="mb-2 img-yec-test"
                  />
                  <div class="pe-5">
                    <p class="fs-16 fs-xs-14">
                      Universitas Amikom Yogyakarta merupakan perubahan dari
                      Sekolah Tinggi Manajemen Informatika dan Komputer Amikom
                      Yogyakarta. Perubahan bentuk ini dilakukan untuk
                      mempermudah mencapai visinya sebagai perguruan tinggi
                      unggulan dunia dalam bidang ekonomi kreatif berbasis
                      entrepreneurship.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <p class="fs-18 fs-xs-16 font-weight-bold mb-2">Alamat</p>
                  <div class="pe-5">
                    <p class="fs-16 fs-xs-14 mb-0">
                      Jl. Padjajaran, Ring Road Utara, Kel. Condongcatur, Kec.
                      Depok, Kab. Sleman, Prop. Daerah Istimewa Yogyakarta 55283
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <p class="fs-18 fs-xs-16 font-weight-bold mb-2">
                    Customer Service
                  </p>
                  <span class="mb-2">
                    <a
                      href="https://www.facebook.com/amikomjogja/"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/FB.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/amikomjogja/"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/instagram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a href="https://twitter.com/amikomjogja" target="_blank">
                      <img
                        src="@/assets/images/twitter.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 30px"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/@amikomjogjavideo"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/youtube.png"
                        alt="Avatar"
                        class=""
                        style="width: 25px"
                      />
                    </a>
                  </span>
                  <p class="fs-16 fs-xs-14 mb-0">Email : amikom@amikom.ac.id</p>
                  <p class="fs-16 fs-xs-14">Telp: (0274) 884201 – 207</p>
                </div>
              </div>
            </div>
          </div>
        </v-main>
      </div>
      <v-snackbar
        top
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="showScan" persistent>
        <v-btn
          v-show="scanBarcode"
          @click="showScan = false"
          style="position: absolute; right: 0; top: 0"
          icon
        >
          <v-icon color="#000" large>ri-close-circle-line</v-icon>
        </v-btn>
        <v-card v-if="scanBarcode" class="pa-4">
          <v-card-text class="pa-0 text-center">
            <p class="text-h6 font-weight-normal">
              Scan Barcode untuk absen masuk kelas yang sudah dibeli
            </p>
            <StreamBarcodeReader
              @decode="onDecode"
              @loaded="onLoaded"
            ></StreamBarcodeReader>
          </v-card-text>
        </v-card>
        <v-card v-if="result" class="rounded-xl pa-4">
          <v-card-text class="pb-0 text-center">
            <span class="text-h5">Scan QR Code Absen Kelas</span>
            <div class="col-md-8 mx-auto pt-0">
              <p class="mb-0 text-body-1 pt-3">{{ program }}</p>
            </div>
            <template v-if="success_scan">
              <v-icon large color="success" class="mb-3"
                >ri-checkbox-circle-fill</v-icon
              >
              <p
                color="success"
                class="text-body-1 font-weight-bold success--text"
              >
                Berhasil
              </p>
            </template>
            <template v-if="!success_scan">
              <v-alert type="error" text v-show="response !== null">{{
                response
              }}</v-alert>
              <v-icon large color="error" class="mb-3"
                >ri-close-circle-line</v-icon
              >
              <p color="error" class="text-body-1 font-weight-bold error--text">
                Gagal
              </p>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </transition>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firebase-messaging";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader,
  },

  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      total: null,
      notifications: [1],
      pageTitle: "",
      notif: [],
      snackbar: {
        state: false,
        text: "",
      },
      timeout: 5000,
      // title:false,
      pathnya: "",
      dialog: {
        menuAuth: false,
      },
      attend: false,
      items: [
        // { title: "Beranda", icon: "$beranda", link: "/beranda" },
        // { title: "Kelas MOOC", icon: "ri-community-line", link: "/prakerja" },
        { title: "Kelas Saya", icon: "$kelas", link: "/kelas" },
      ],
      showScan: false,
      scanBarcode: true,
      result: false,
      success_scan: null,
      program: "",
      response: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$root.$on("setDialog", (attend) => {
      this.attend = attend;
    });
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
    });
    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;

    // try {
    //   firebase
    //     .messaging()
    //     .requestPermission()
    //     .then(() => {
    //       firebase
    //         .messaging()
    //         .getToken()
    //         .then((token) => {
    //           this.receiveMessage();
    //         });
    //     })
    //     .catch((err) => {
    //       console.log("Unable to get token ", err);
    //     });
    // } catch (e) {
    //   console.log(e);
    // }
  },

  mounted() {
    this.getAll();
    document.addEventListener(
      "visibilitychange",
      async () => {
        if (!document.hidden) {
          this.getAll();
        }
      },
      false
    );
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    }
    if (this.user.id) {
      if (this.$firebaseIsSupported) {
        this.fcmToken().then(() => {});
        // this.$messaging.onTokenRefresh(this.fcmToken());
        this.$messaging.onTokenRefresh((data) => {
          console.log("onTokenRefresh...", data);
          this.fcmToken();
        });
      }
    }
  },
  methods: {
    onDecode(result) {
      if (result) {
        const data = {
          member_redeem_id: result,
        };
        this.axios
          .post("presence/v1/scan", data)
          .then(() => {
            this.success_scan = true;
            this.getDetailClass(result);
            this.result = true;
            this.scanBarcode = false;
            setTimeout(() => {
              this.success_scan = null;
              this.showScan = false;
              this.result = false;
              this.scanBarcode = true;
            }, 5000);
          })
          .catch((error) => {
            this.success_scan = false;
            this.getDetailClass(result);
            this.result = true;
            this.scanBarcode = false;
            this.res = false;
            this.response = error.response.data.message;
            setTimeout(() => {
              this.success_scan = null;
              this.result = false;
              this.scanBarcode = true;
              this.res = false;
              this.response = null;
            }, 5000);
          });
      }
    },
    getDetailClass() {
      this.loadContent = true;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.$route.params.id}`)
        .then((response) => {
          this.program = response.data.data.program.title;
        });
    },
    onLoaded() {},
    getAll() {
      this.getNotif();
      this.getNotifUnread();
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          console.log("payload ", payload);
        });
      } catch (e) {
        console.log(e);
      }
    },
    fcmToken() {
      this.$messaging
        .requestPermission()
        .then(async () => {
          this.$messaging
            .getToken()
            .then(async (token) => {
              // console.log("token ", token);
              if (token && token != "") {
                this.axios
                  .post(`/notification/v1/member/subscribe`, {
                    token_fcm: token,
                  })
                  .then(async () => {
                    this.getNotif();
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                this.$messaging.onMessage(async (payload) => {
                  console.log("onMessage", payload);
                  this.total = this.total + 1;
                  this.getAll();
                  // console.log("payload ", payload);
                  // const notificationTitle = payload.notification.title;
                  const notificationTitle = payload.data.title;
                  const notificationOptions = {
                    // body: payload.notification.body,
                    body: payload.data.body,
                    icon: "https://i.postimg.cc/vTFgKvRZ/favicon.png",
                  };
                  /* return navigator.serviceWorker.showNotification(
                      notificationTitle,
                      notificationOptions
                    ) */
                  navigator.serviceWorker
                    .getRegistration()
                    .then((registration) => {
                      // console.log(registration);
                      if (registration) {
                        // if (registration.length > 0) {
                        return registration.showNotification(
                          notificationTitle,
                          notificationOptions
                        );
                      }
                    });
                });
              }
            })
            .catch((err) => {
              console.log("Err ", err);
            });
        })
        .catch((err) => {
          console.log("Unable to get token ", err);
        });
    },
    getNotif() {
      // console.log('get notif')
      // let timestamp='2021-12-02T09:07:24.247Z';
      // console.log(moment.utc(timestamp).fromNow());
      this.axios
        .get("notification/v1/member/list?limit=10&page=1&sort_by=_id&dir=desc")
        .then((response) => {
          let res = response.data.data;
          // if (response.status == 200) {
          this.notif = res.list;
          // this.total=res.total_data;
          // console.log('list',this.notif)
          // }
        });
    },
    getNotifUnread() {
      // let timestamp='2021-12-02T09:07:24.247Z';
      // console.log(moment.utc(timestamp).fromNow());
      this.axios
        .get(
          "notification/v1/member/list?limit=10&page=1&sort_by=_id&dir=desc&reads=0"
        )
        .then((response) => {
          let res = response.data.data;
          // if (response.status == 200) {
          // this.notif = res.list;
          this.total = res.total_data;
          // console.log('list',this.notif)
          // }
        });
    },
    currentDateTime(a) {
      return moment.utc(a).locale("ID").fromNow();
    },
    changeStatus(id) {
      // console.log(id)
      this.axios.put(`notification/v1/member/read/${id}`).then(() => {
        this.snackbar.text = `Notifikasi sudah terbaca`;
        this.snackbar.state = true;
        this.getAll();
      });
    },
    Logout() {
      this.$store.dispatch("logout").then(() => {
        const a = document.createElement("a");
        a.href = "https://potensa.amikom.ac.id/";
        a.click();
      });
      this.dialog.menuAuth = false;
    },
    Setting() {
      this.$router.push("/account");
      this.dialog.menuAuth = false;
    },
    goToSubscription() {
      this.$router.push("/subscription");
      this.dialog.menuAuth = false;
    },
    Beranda() {
      this.$router.push("/beranda");
      this.dialog.menuAuth = false;
    },
    Kelas() {
      this.$router.push("/kelas");
      this.dialog.menuAuth = false;
    },
    Prakerja() {
      this.$router.push("/prakerja");
      this.dialog.menuAuth = false;
    },
    Sertifikat() {
      this.$router.push("/sertifikat");
      window.location.reload(true);

      this.dialog.menuAuth = false;
    },
    Tele() {
      window.open("https://chat.whatsapp.com/GJ7R9A6e6qvLMOWHYFFtwV", "_blank");
    },
    menu() {
      this.$router.push("/");
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
.bgRead {
  background-color: rgba(240, 83, 38, 0.1);
}
.content-wrapper {
  position: relative;
}
/*.menu-active::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0;
  background: var(--v-primary-base);
}*/
.img-yec {
  height: 50px;
  width: 100%;
  object-fit: contain;
}
/*.img-yec:hover {
  cursor: pointer;
}*/
.img-yec-test {
  height: 40px;
  object-fit: contain;
}
#list-menu-user .v-list-item {
  padding: 8px 16px;
}
/*.img-yec-test:hover {
  cursor: pointer;
}*/
</style>