<template>
  <div class="d-flex justify-space-between pa-4">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <v-badge v-if="newtabActive" color="red" :content="newtab || '0'">
            <v-icon>ri-window-fill</v-icon>
          </v-badge>
          <v-icon v-else color="#aaa">ri-window-fill</v-icon>
        </div>
      </template>
      <span v-if="newtabActive">Jumlah Pelanggaran Tab Baru: {{ newtab }}</span>
      <span v-else>Deteksi kecurangan tab baru tidak aktif</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <v-badge v-if="faceActive" color="red" :content="face || '0'">
            <v-icon>ri-user-smile-fill</v-icon>
          </v-badge>
          <v-icon v-else color="#aaa">ri-user-smile-fill</v-icon>
        </div>
      </template>
      <span v-if="faceActive">Jumlah Pelanggaran Wajah Ganda: {{ face }}</span>
      <span v-else>Deteksi kecurangan multi wajah tidak aktif</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <v-badge v-if="voiceActive" color="red" :content="voice || '0'">
            <v-icon>ri-mic-fill</v-icon>
          </v-badge>
          <v-icon v-else color="#aaa">ri-mic-fill</v-icon>
        </div>
      </template>
      <span v-if="voiceActive">Jumlah Pelanggaran Kebisingan: {{ voice }}</span>
      <span v-else>Deteksi kecurangan kebisingan tidak aktif</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    voiceActive: Boolean,
    faceActive: Boolean,
    newtabActive: Boolean,
    voice: [String, Number],
    face: [String, Number],
    newtab: [String, Number],
  },
};
</script>