import Vue from "vue";
import VueRouter from "vue-router";
import Views from "../views";
import store from "../store";

Vue.use(VueRouter);

let routes = {
	auth: [
    {
      path: "/checkpoint/:id/start",
      name: "checkpoint",
      component: Views.User.Checkpoint.EpotPreparation,
    },
    {
      path: "/checkpoint/:id/disclaimer",
      name: "checkpoint-disclaimer",
      component: Views.User.Checkpoint.EpotSpecification,
    },
    {
      path: "/checkpoint/:id/disclaimer",
      name: "checkpoint-specification",
      component: Views.User.Checkpoint.EpotSpecification,
    },
    {
      path: "/checkpoint/:id/device",
      name: "checkpoint-device",
      component: Views.User.Checkpoint.EpotDeviceVerification,
    },
		{ path: "/beranda", name: "beranda", redirect: "/kelas" },
		{ path: "/studi", name: "studi", component: Views.User.Home.StudiUser },
		{
			path: "/account",
			name: "account",
			component: Views.User.Account.AccountSetting,
		},
		{
			path: "/subscription",
			name: "subscription",
			component: Views.User.Subscription.Subscription,
		},
		{ path: "/prakerja", name: "prakerja", component: Views.User.Home.Prakerja },
		{
			path: "/detail-kelas-user/:id",
			name: "detail-kelas-user",
			component: Views.User.Home.DetailUser,
		},
		{ path: "/kelas", name: "my-class", component: Views.User.MyClass.MyClass },
		{
			path: "/kelas/:id",
			name: "detail-class",
			component: Views.User.MyClass.DetailClass,
		},
		{
			path: "/kelas-v2/:id",
			name: "detail-class-v2",
			component: Views.User.MyClass.DetailClassV2,
		},
	],
	guest: [
		{ path: "/", name: "home", component: Views.Public.PublicBeranda },
		{
			path: "/bidang-studi",
			name: "bidang-studi",
			component: Views.Public.PublicStudi,
		},
		{
			path: "/detail-kelas/:id",
			name: "detail-kelas",
			component: Views.Public.PublicDetail,
		},
		{ path: "/auth/login", name: "login", redirect: "/auth/login-potensa" },
		{ path: "/auth/login-potensa", component: Views.Auth.loginPotensa },
		{
			path: "/auth/success-verify-email/:code",
			name: "success-verify-email",
			component: Views.Auth.SuccessVerif,
		},
		{
			path: "/auth/registration",
			name: "registration",
			component: Views.Auth.Registration,
		},
		{
			path: "/auth/password-send-phone",
			name: "password-send-phone",
			component: Views.Auth.PasswordSendEmail,
		},
		{
			path: "/auth/success-send-email",
			name: "success-send-email",
			component: Views.Auth.SuccessSendEmail,
		},
		{
			path: "/auth/password-reset/:id",
			name: "password-reset",
			component: Views.Auth.PasswordReset,
		},
		{
			path: "/auth/success-reset-password",
			name: "success-reset-password",
			component: Views.Auth.SuccessResetPassword,
		},
		{
			path: "/kelas-prakerja",
			name: "/kelas-prakerja",
			component: Views.Public.PublicPrakerja,
		},
		{
			path: "/auth/kirim-otp",
			name: "kirim-otp",
			component: Views.Otp.inputPhone,
		},
	],
	default: [
		{
			path: "/certificate-participant/:id",
			name: "certificate-participant",
			component: Views.User.Certif.CertifPart,
		},
		{
			path: "/certificate-graduation/:id",
			name: "certificate-graduation",
			component: Views.User.Certif.CertifGrad,
		},
		{ path: "/maintenance", name: "maintenance", component: Views.Maintenance },
		{ path: "*", name: "not-found", component: Views.Errors.NotFound },
	],
};

routes.auth.forEach((route) => {
	route.meta = { ...route.meta, auth: true };
});
routes.guest.forEach((route) => {
	route.meta = { ...route.meta, guest: true };
});
routes = [...routes.auth, ...routes.guest, ...routes.default];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (from.meta.prevent_unload) {
		alert("Mohon maaf, anda tidak diperkenankan untuk meninggalkan halaman ini.");
		return false;
	}
	const under_maintenance = store.state.company.under_maintenance;
	const phone_number = store.state.company.phone_number;

	if (under_maintenance) {
		if (to.path !== "/maintenance") {
			if (store.getters.isLoggedIn) {
				if (phone_number.includes(store.getters.user.phone)) {
					if (to.meta.auth) {
						next();
					}
					if (to.path == "/" || to.meta.guest) {
						next("/beranda");
					}
				} else {
					next("/maintenance");
				}
			} else {
				next("/maintenance");
			}
		} else {
			next();
		}
	} else {
		if (to.path == "/maintenance") {
			next("/");
		} else {
			next();
		}
		if (store.getters.isLoggedIn) {
			if (
				store.getters.user.is_updated_profile === false &&
				to.path != "/account"
				) {
					next("/account");
				}
				if (to.path == "/" || to.meta.guest) {
					next("/beranda");
				}
			}
			if (to.path == "/account" && !!to.query.token) {
				return next();
			}
			if (to.meta.auth && !store.getters.isLoggedIn) {
				localStorage.setItem("ytc_prakerja_redirect", location.pathname);
				next("/");
			}
		}
	});

	export default router;
