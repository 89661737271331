export default { 
  Login: require('./login').default,
  Registration: require('./registration').default,
  PasswordSendEmail: require('./PasswordSendEmail').default,
  SuccessSendEmail: require('./SuccessSendEmail').default,
  SuccessVerif: require('./SuccessVerif').default,
  SuccessResetPassword: require('./SuccessResetPassword').default,
  PasswordReset: require('./PasswordReset').default,
  loginPotensa: require('./loginPotensa').default,
  // ForgotPassword: require('./ForgotPassword').default,
  // PasswordChanged: require('./PasswordChanged').default,
}